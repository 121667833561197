// @ts-nocheck
import { useEventStore } from './stores/eventStore';
import { useFeatureStore } from './stores/featureStore';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as Sentry from '@sentry/react';
import flagsmith from 'flagsmith';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Recapture from 'src/recapture';
import { useLocalStorage } from 'usehooks-ts';

import CaptureRootPage from 'src/pages/CaptureRootPage/CaptureRootPage';
import RecaptureRootPage from 'src/pages/CaptureRootPage/RecaptureRootPage';
import ConfirmPhotosPage from 'src/pages/ConfirmPhotosPage/ConfirmPhotosPage';
import DamageCapture from 'src/pages/Damage/DamageCapture/DamageCapture';
import DamageExample from 'src/pages/Damage/DamageExample/DamageExample';
import RoomCapture from 'src/pages/Damage/RoomCapture/Index';
import HomePage from 'src/pages/HomePage';
import LandingPage from 'src/pages/LandingPage/LandingPage';
import RecaptureLanding from 'src/pages/LandingPage/RecaptureLanding';
import RoomSelectPage from 'src/pages/RoomSelect/RoomSelectPage';
import RoomSubmittedPage from 'src/pages/RoomSubmittedPage/RoomSubmittedPage';
import SurveyPage from 'src/pages/SurveyPage/SurveyPage';
import VideoTipsPage from 'src/pages/VideoTipsPage/VideoTipsPage';

import ErrorElement from 'src/components/ErrorElement';
import Modal from 'src/components/Modal/Modal';
import RedirectPage from 'src/components/Redirect';

function App() {
  const [isLoad, setIsLoad] = useState(false);
  const [isReturningUser] = useLocalStorage('returningUser', false);
  const features = useFeatureStore();
  const eventStore = useEventStore();

  useEffect(() => {
    // Init Feature Toggle
    flagsmith.init({
      environmentID: process.env.REACT_APP_FLAGSMITH_ID || '',
      onChange: () => {
        features.toggleAiAssist(flagsmith.hasFeature('ai_assistance'));
        features.toggleTipsVisible(
          !(
            flagsmith.hasFeature('hide_tips_returning_users') &&
            Boolean(isReturningUser)
          )
        );
        features.toggleCollectContactInfo(
          flagsmith.hasFeature('collect_contact_info')
        );
        features.toggleCollectRoomName(
          flagsmith.hasFeature('collect_room_info')
        );
        features.toggleInBrowserCapture(
          flagsmith.hasFeature('in_browser_capture')
        );
        // Check for returning User
        features.toggleInstructionalVideo(
          flagsmith.hasFeature('instructional_video') &&
            !(
              flagsmith.hasFeature('hide_tips_returning_users') &&
              Boolean(isReturningUser)
            )
        );
        features.toggleNewSubmitLoader(
          flagsmith.hasFeature('new_submit_loader')
        );
        features.toggleLayoutCapture(flagsmith.hasFeature('layout_capture'));
        // features.toggleDamage(flagsmith.hasFeature('enable_damages'));
      },
    });
    // Init Mix Panel
    mixpanel.init(process.env.REACT_APP_MIXPANEL_ID || '', {
      debug: true,
      track_pageview: 'full-url',
    });
    mixpanel.set_config({ persistence: 'localStorage' });
    setTimeout(() => {
      setIsLoad(true);
    }, 2000);
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    eventStore.setLoading(false);
    eventStore.setError('');
  };

  if (!isLoad) return <div />;

  return (
    <div className="capture-app min-h-full mx-auto">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={eventStore.loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {eventStore.error && (
        <Modal
          handleClose={handleClose}
          title="Caution"
          text={String(eventStore.error)}
        />
      )}
      <ErrorElement>
        <Routes data-testid="room-capture-router">
          <Route path="" element={<HomePage />} />
          <Route
            path="recapture"
            element={<Outlet />}
            errorElement={<ErrorElement />}
          >
            <Route path=":linkId" element={<Recapture />}></Route>
          </Route>
          <Route
            path=":captureType/:claimId/:returnId?"
            element={<CaptureRootPage />}
            errorElement={<ErrorElement />}
          >
            <Route
              path=""
              element={<LandingPage />}
              errorElement={<ErrorElement />}
            />
            <Route
              path="room-select"
              element={<RoomSelectPage />}
              errorElement={<ErrorElement />}
            />
            <Route path=":roomId" element={<Outlet />}>
              <Route
                path="damage-example"
                element={<DamageExample />}
                errorElement={<ErrorElement />}
              />
              <Route
                path="damage"
                element={<DamageCapture />}
                errorElement={<ErrorElement />}
              />
              <Route
                path="tips"
                element={<VideoTipsPage />}
                errorElement={<ErrorElement />}
              />
              <Route
                path="capture-room"
                element={<RoomCapture />}
                errorElement={<ErrorElement />}
              />
              <Route
                path="confirm-photos"
                element={<ConfirmPhotosPage />}
                errorElement={<ErrorElement />}
              />
              <Route
                path="room-submitted"
                element={<RoomSubmittedPage />}
                errorElement={<ErrorElement />}
              />
              <Route
                path="feedback"
                element={<SurveyPage />}
                errorElement={<ErrorElement />}
              />
            </Route>
          </Route>
          <Route
            path=":claimId"
            element={<RedirectPage />}
            errorElement={<ErrorElement />}
          />
          <Route
            path=":captureType/:claimId/:roomId/recapture"
            element={<RecaptureRootPage />}
            errorElement={<ErrorElement />}
          >
            <Route
              path=""
              element={<RecaptureLanding />}
              errorElement={<ErrorElement />}
            />
          </Route>
        </Routes>
      </ErrorElement>
    </div>
  );
}

export default Sentry.withProfiler(App, { name: 'WebCapture' });
